App.Modules = App.Modules || {};
App.Modules.maps = function () {

    var maps = function(data) {
        jQuery('.maps').click(function () {
				    jQuery('.maps iframe').css("pointer-events", "auto");
				});

				jQuery( ".maps" ).mouseleave(function() {
				  jQuery('.maps iframe').css("pointer-events", "none"); 
				});
    };

    return {
        init: function() { 
        	maps();
      		return this; 
    		},
        events: function() {
            return this;
        }
    };
}();