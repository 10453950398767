App.Modules = App.Modules || {};
App.Modules.playProjectVideo = function () {

    var playVideo = function(data) {
        data.event.preventDefault();

        jQuery(data.elem).fadeOut(550);
        jQuery('.js-video-player').fadeIn(650);
        jQuery(".video-frame")[0].src = jQuery('.video-frame').data('video');
    };

    return {
        init: function() { return this; },
        events: function() {
            Events.bind("click", ".js-play-video").to(playVideo, this);
            return this;
        }
    };
}();